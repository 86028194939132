import React from 'react'

import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { asyncSetState } from 'utils/react'

import UserContentTable from './components/UserContentTable/UserContentTable'

import API from 'api'

import { TABLE_PARAMS, MODALS } from './constants'

class UserContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            userContent: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },

            modal: {
                isOpen: false,
                name: '',
                payload: null,
            },
        }
    }

    async componentDidMount() {
        await this.getUserContent()
        this.setState({
            isReady: true,
        })
    }

    getUserContent = async (isClear = true) => {
        try {
            const { authorId } = this.props
            const {
                userContent: { requestParams },
            } = this.state
            this.setState(prevState => ({
                userContent: {
                    ...prevState.userContent,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.CONTENT.GET_USER_CONTENT({ ...requestParams, authorId })

            await asyncSetState(this, prevState => ({
                userContent: {
                    ...prevState.userContent,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.userContent.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                userContent: {
                    ...prevState.userContent,
                    isLoading: false,
                },
            }))
        }
    }

    approveUserContent = async id => {
        await API.CONTENT.ADD_USER_CONTENT(id)
        await this.getUserContent()
        Toast(TOAST_TYPE.SUCCESS, { message: 'Public content is approved successfully' })
    }
    denyUserContent = async id => {
        await API.CONTENT.DELETE_USER_CONTENT(id)
        await this.getUserContent()
        Toast(TOAST_TYPE.SUCCESS, { message: 'Public content is deny successfully' })
    }

    openModal = async (name, payload = null) => {
        await asyncSetState(this, prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }

    closeModal = async () => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: false,
            },
        }))
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            userContent: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            userContent: {
                ...prevState.userContent,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getUserContent(isClear)
    }

    render() {
        const { isReady, userContent, modal } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return isReady ? (
            <>
                <UserContentTable
                    list={userContent.list}
                    methods={{
                        openModal: this.openModal,
                        changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                    }}
                    pagination={{
                        currentPage: userContent.lastResponse.number,
                        totalPages: userContent.lastResponse.totalPages,
                        perPageElements: userContent.lastResponse.numberOfElements,
                        totalElements: userContent.lastResponse.totalElements,
                    }}
                    requestParams={userContent.requestParams}
                    onExpand={() =>
                        this.changeRequestParamsAndRefresh(
                            [
                                {
                                    field: 'page',
                                    value: userContent.lastResponse.number + 1,
                                },
                            ],
                            false,
                        )
                    }
                    onChangePage={page =>
                        this.changeRequestParamsAndRefresh([
                            {
                                field: 'page',
                                value: page,
                            },
                        ])
                    }
                    isLoading={userContent.isLoading}
                />

                {isOpenedModal(MODALS.APPROVE_CONTENT) && (
                    <ConfirmationDialog
                        onClose={() => this.closeModal()}
                        noteText="Are your sure that you want to publish this user template in Community gallery?"
                        targetText={modal.payload.content.title}
                        actionText="Publish"
                        onAction={async () => {
                            await this.approveUserContent(modal.payload.content.id)
                        }}
                    />
                )}
                {isOpenedModal(MODALS.DENY_CONTENT) && (
                    <ConfirmationDialog
                        onClose={() => this.closeModal()}
                        noteText="Are your sure that you want to hide this user template from Community gallery?"
                        targetText={modal.payload.content.title}
                        actionVariant="danger"
                        actionText="Hide"
                        onAction={async () => {
                            await this.denyUserContent(modal.payload.content.id)
                        }}
                    />
                )}
            </>
        ) : (
            <Preloader />
        )
    }
}

export default UserContent
