import React from 'react'

import Preloader from 'components/Preloader/Preloader'
import Button from 'components/Forms/Button/Button'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import EditProjectModal from 'components/Modal/EditProjectModal/EditProjectModal'
import SEO from 'components/SEO/SEO'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import ProjectsTable from './components/ProjectsTable/ProjectsTable'
import InfoBlocks from './components/InfoBlocks/InfoBlocks'
import EditTextModal from './components/EditTextModal/EditTextModal'
import InterestsTags from './components/InterestsTags/InterestsTags'

import './AccountAndProjects.scss'

import iconChecked from './i/checked.png'
import iconUnchecked from './i/unchecked.png'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { asyncSetState } from 'utils/react'

import API from 'api'

import { TABLE_PARAMS, MODALS, MODAL_ACTIONS } from './constants'

class AccountAndProjectsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            projects: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },

            modal: {
                isOpen: false,
                isLoading: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        const {
            user: { isActive },
        } = this.props
        if (isActive) {
            await this.getProjects()
        }
        this.setState({
            isReady: true,
        })
    }

    getProjects = async (isClear = true) => {
        try {
            const {
                user: { id },
            } = this.props
            const {
                projects: { requestParams },
            } = this.state

            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.PROJECTS.GET_USER_PROJECTS(id, requestParams)
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.projects.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: false,
                },
            }))
        }
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            projects: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            projects: {
                ...prevState.projects,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getProjects(isClear)
    }

    openModal = async (name, payload = {}) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        if (this.state.modal.isLoading) return
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }
    modalAction = async (actionName, payload = {}) => {
        try {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: true,
                },
            }))

            switch (actionName) {
                case MODAL_ACTIONS.BLOCK_PROJECT: {
                    await API.PROJECTS.BLOCK_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.UNBLOCK_PROJECT: {
                    await API.PROJECTS.UNBLOCK_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.BLOCK_ALL_PROJECTS: {
                    const {
                        user: { id },
                    } = this.props
                    await API.PROJECTS.BLOCK_ALL_USER_PROJECTS(id)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.UNBLOCK_ALL_PROJECTS: {
                    const {
                        user: { id },
                    } = this.props
                    await API.PROJECTS.UNBLOCK_ALL_USER_PROJECTS(id)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.REMOVE_PROJECT: {
                    await API.PROJECTS.REMOVE_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.SET_USER_TEST: {
                    const {
                        user: { id },
                        methods: { getUser },
                    } = this.props
                    await API.USERS.SET_IS_TEST(id)
                    await getUser()
                    break
                }
                case MODAL_ACTIONS.SET_USER_REAL: {
                    const {
                        user: { id },
                        methods: { getUser },
                    } = this.props
                    await API.USERS.SET_IS_REAL(id)
                    await getUser()
                    break
                }
                case MODAL_ACTIONS.BLOCK_ACCOUNT: {
                    const {
                        user: { id },
                        methods: { getUser },
                    } = this.props
                    await API.USERS.BLOCK_ACCOUNT(id)
                    await getUser()
                    break
                }
                case MODAL_ACTIONS.UNBLOCK_ACCOUNT: {
                    const {
                        user: { id },
                        methods: { getUser },
                    } = this.props
                    await API.USERS.UNBLOCK_ACCOUNT(id)
                    await getUser()
                    break
                }
                case MODAL_ACTIONS.DELETE_ACCOUNT: {
                    const {
                        user: { id },
                        history,
                    } = this.props
                    await API.USERS.DELETE_ACCOUNT(id)
                    history.push('/users')
                    break
                }
                case MODAL_ACTIONS.ACTIVATE_ACCOUNT: {
                    const {
                        user: { id },
                        methods: { getUser },
                    } = this.props
                    await API.USERS.ACTIVATE_ACCOUNT(id)
                    await this.getProjects()
                    await getUser()
                    break
                }
                case MODAL_ACTIONS.EDIT_FIRST_NAME:
                case MODAL_ACTIONS.EDIT_LAST_NAME: {
                    const {
                        user,
                        methods: { getUser },
                    } = this.props
                    await API.USERS.UPDATE_USER({ ...user, ...payload })
                    await getUser()
                    break
                }
                default:
                    break
            }
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isSuccess: true,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: false,
                },
            }))
        }
    }

    render() {
        const { user, userVisibleName } = this.props
        const { isReady, projects, modal } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title={`Users / ${userVisibleName}`} />

                <div className="top-note">
                    <div className="note">
                        {user.isActive ? (
                            <>
                                <img src={iconChecked} alt="" />
                                <span>User is active</span>
                                {user.isBlocked && (
                                    <>
                                        &nbsp;<span style={{ color: '#FF5656' }}>[BLOCKED]</span>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <img src={iconUnchecked} alt="" />
                                <span>User is not active</span>
                                <Button
                                    content="Activate account"
                                    styles={{
                                        marginLeft: 15,
                                    }}
                                    variant="primary"
                                    onClick={() =>
                                        this.openModal(MODALS.CONFIRMATION, {
                                            data: {
                                                noteText: 'Are you sure you want to activate this account?',
                                                actionVariant: 'primary',
                                                actionText: 'Activate',
                                                successText: 'Account is activated successfully!',
                                            },
                                            onAction: () => this.modalAction(MODAL_ACTIONS.ACTIVATE_ACCOUNT),
                                        })
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="buttons-list">
                        {user.isActive &&
                            (user.isBlocked ? (
                                <Button
                                    content="Unblock account"
                                    styles={{
                                        marginRight: 10,
                                    }}
                                    variant="primary"
                                    onClick={() =>
                                        this.openModal(MODALS.CONFIRMATION, {
                                            data: {
                                                noteText: 'Are you sure you want to unblock this account?',
                                                actionVariant: 'primary',
                                                actionText: 'Unblock',
                                                successText: 'Account is unblocked successfully!',
                                            },
                                            onAction: () => this.modalAction(MODAL_ACTIONS.UNBLOCK_ACCOUNT),
                                        })
                                    }
                                />
                            ) : (
                                <Button
                                    content="Block account"
                                    styles={{
                                        marginRight: 10,
                                    }}
                                    variant="danger"
                                    onClick={() =>
                                        this.openModal(MODALS.CONFIRMATION, {
                                            data: {
                                                noteText: 'Are you sure you want to block this account?',
                                                actionVariant: 'danger',
                                                actionText: 'Block',
                                                successText: 'Account is blocked successfully!',
                                            },
                                            onAction: () => this.modalAction(MODAL_ACTIONS.BLOCK_ACCOUNT),
                                        })
                                    }
                                />
                            ))}
                        <Button
                            content="Delete account"
                            variant="danger"
                            onClick={() =>
                                this.openModal(MODALS.CONFIRMATION, {
                                    data: {
                                        noteText: 'Are you sure you want to delete this account?',
                                        actionVariant: 'danger',
                                        actionText: 'Delete',
                                        successText: 'Account is deleted successfully!',
                                    },
                                    onAction: () => this.modalAction(MODAL_ACTIONS.DELETE_ACCOUNT),
                                })
                            }
                        />
                    </div>
                </div>

                <br />
                <br />

                <InfoBlocks user={user} openModal={this.openModal} modalAction={this.modalAction} />

                {isReady ? (
                    <>
                        {user.isActive && (
                            <>
                                <br />
                                <br />
                                <InterestsTags tags={user.interestTags} />
                                <br />
                                <br />
                                <br />
                                <div className="buttons-list">
                                    <Button
                                        content="Unblock all projects"
                                        styles={{
                                            marginRight: 10,
                                        }}
                                        variant="primary"
                                        onClick={() =>
                                            this.openModal(MODALS.CONFIRMATION, {
                                                data: {
                                                    noteText: 'Are you sure you want to unblock all user project?',
                                                    actionVariant: 'primary',
                                                    actionText: 'Unblock',
                                                    successText: 'All user projects is unblocked successfully!',
                                                },
                                                onAction: () => this.modalAction(MODAL_ACTIONS.UNBLOCK_ALL_PROJECTS),
                                            })
                                        }
                                    />
                                    <Button
                                        content="Block all projects"
                                        variant="danger"
                                        onClick={() =>
                                            this.openModal(MODALS.CONFIRMATION, {
                                                data: {
                                                    noteText: 'Are you sure you want to block all user project?',
                                                    actionVariant: 'danger',
                                                    actionText: 'Block',
                                                    successText: 'All user projects is blocked successfully!',
                                                },
                                                onAction: () => this.modalAction(MODAL_ACTIONS.BLOCK_ALL_PROJECTS),
                                            })
                                        }
                                    />
                                </div>
                                <br />

                                <ProjectsTable
                                    title={
                                        <>
                                            Projects
                                            <span
                                                style={{
                                                    fontSize: '12px',
                                                    paddingLeft: '10px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                (number of published: {user.numberOfPublishedProject})
                                            </span>
                                        </>
                                    }
                                    list={projects.list}
                                    methods={{
                                        openModal: this.openModal,
                                        modalAction: this.modalAction,
                                        changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                                    }}
                                    pagination={{
                                        currentPage: projects.lastResponse.number,
                                        totalPages: projects.lastResponse.totalPages,
                                        perPageElements: projects.lastResponse.numberOfElements,
                                        totalElements: projects.lastResponse.totalElements,
                                    }}
                                    requestParams={projects.requestParams}
                                    onExpand={() =>
                                        this.changeRequestParamsAndRefresh(
                                            [
                                                {
                                                    field: 'page',
                                                    value: projects.lastResponse.number + 1,
                                                },
                                            ],
                                            false,
                                        )
                                    }
                                    onChangePage={page =>
                                        this.changeRequestParamsAndRefresh([
                                            {
                                                field: 'page',
                                                value: page,
                                            },
                                        ])
                                    }
                                    isLoading={projects.isLoading}
                                />
                            </>
                        )}

                        {isOpenedModal(MODALS.CONFIRMATION) && (
                            <ConfirmationDialog
                                onClose={() => this.closeModal()}
                                onAction={() => modal.payload.onAction()}
                                {...modal.payload.data}
                            />
                        )}

                        {(isOpenedModal(MODALS.EDIT_FIRST_NAME) || isOpenedModal(MODALS.EDIT_LAST_NAME)) && (
                            <EditTextModal
                                onClose={() => this.closeModal()}
                                onAction={props => modal.payload.onAction(props)}
                                {...modal.payload.data}
                            />
                        )}

                        {isOpenedModal(MODALS.EDIT_PROJECT) && (
                            <EditProjectModal
                                onClose={() => this.closeModal()}
                                onRefresh={async () => await this.getProjects()}
                                projectId={modal.payload.projectId}
                            />
                        )}
                    </>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default AccountAndProjectsPage
