import React from 'react'
import classNames from 'classnames'

const Wrapper = ({ isRowHasAnyMarks, rowNumber, isChildren = false, children }) => (
    <ul
        className={classNames('common__table__content__row', {
            'is-has-marks': isRowHasAnyMarks,
            'is-children': isChildren,
        })}
        data-row-number={rowNumber}
    >
        {children}
    </ul>
)

export default Wrapper
