import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `orderNumber,${SORT_ORDERS.asc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 20,
        getValue: v => Number(v),
    },
    id: {
        getValue: v => Number(v),
    },
    name: {},
    isEnabled: {
        getValue: v => v === 'true',
    },
    isPremium: {
        getValue: v => v === 'true',
    },
    tags: {},
}

export const MODALS = {
    REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
    CREATE_TEMPLATE: 'CREATE_TEMPLATE',
    EDIT_TEMPLATE: 'EDIT_TEMPLATE',
    CONFIRMATION: 'CONFIRMATION',
}
