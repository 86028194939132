import React from 'react'
import { DialogButton, insertDirective$, usePublisher } from '@mdxeditor/editor'

import youtubeIcon from './i/youtube.svg'

const YouTubeButton = () => {
    const insertDirective = usePublisher(insertDirective$)

    return (
        <DialogButton
            tooltipTitle="Insert Youtube video"
            submitButtonTitle="Insert video"
            dialogInputPlaceholder="Paste the youtube video URL"
            buttonContent={<img src={youtubeIcon} alt="youtube" />}
            onSubmit={url => {
                try {
                    const videoId = new URL(url).searchParams.get('v')
                    if (videoId) {
                        insertDirective({
                            name: 'youtube',
                            type: 'leafDirective',
                            attributes: { id: videoId },
                            children: [],
                        })
                    } else {
                        alert('Invalid YouTube URL')
                    }
                } catch (err) {
                    alert('Invalid YouTube URL')
                }
            }}
        />
    )
}

export default YouTubeButton
