import React from 'react'
import classNames from 'classnames'

const ColValue = ({ column, row, style, parent = null }) => (
    <div className="common__table__content__col__value" style={style}>
        {column.getValue(row, parent)}
    </div>
)
const ColMarks = ({ column, row }) => {
    const marksArray = column.getMarks(row)
    if (!marksArray.length) return null

    return (
        <div className="common__table__content__col__marks">
            {(() => {
                const marksWithClickHandler = marksArray.filter(mark => !!mark.onClick)
                const marksWithoutClickHandler = marksArray.filter(mark => !mark.onClick)

                return (
                    <>
                        {!!marksWithClickHandler.length && (
                            <ul>
                                {marksWithClickHandler.map((mark, i) => (
                                    <li
                                        className="is-clickable"
                                        key={i}
                                        title={mark.label}
                                        onClick={evt => {
                                            evt.preventDefault()
                                            mark.onClick()
                                        }}
                                    >
                                        {mark.image && <img src={mark.image} alt={mark.label} />}
                                        {!!mark.component && mark.component}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {!!marksWithoutClickHandler.length && (
                            <ul
                                className={classNames({
                                    'with-left-border': !!marksWithClickHandler.length,
                                })}
                            >
                                {marksWithoutClickHandler.map((mark, i) => (
                                    <li key={i} title={mark.label} onClick={evt => evt.stopPropagation()}>
                                        {mark.image && <img src={mark.image} alt={mark.label} />}
                                        {!!mark.component && mark.component}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )
            })()}
        </div>
    )
}

const Row = ({ row, columns, onColumnClick, parent }) =>
    columns.map((column, columnIndex) => (
        <li
            key={columnIndex}
            className={classNames('common__table__content__col', {
                'is-clickable': !!onColumnClick,
            })}
            onClick={() =>
                !!onColumnClick
                    ? onColumnClick({
                          column,
                          row,
                      })
                    : {}
            }
            style={{ width: column.width }}
            title={column.getTitle(row, parent)}
        >
            <ColValue parent={parent} column={column} row={row} style={column.valueStyles} />
            <ColMarks column={column} row={row} />
        </li>
    ))

export default Row
