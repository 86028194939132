import React from 'react'
import {
    MDXEditor,
    headingsPlugin,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    BlockTypeSelect,
    ListsToggle,
    listsPlugin,
    CreateLink,
    linkPlugin,
    linkDialogPlugin,
    imagePlugin,
    InsertImage,
    InsertThematicBreak,
    thematicBreakPlugin,
    Separator,
    directivesPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

import { uploadFile } from 'utils/file'

import YoutubeDirectiveDescriptor from './descriptors/YoutubeDirectiveDescriptor/YoutubeDirectiveDescriptor'

import YouTubeButton from './toolbar/YouTubeButton/YouTubeButton'

import './MarkdownEditor.scss'

const MarkdownEditor = ({ value, onChange }) => (
    <MDXEditor
        markdown={value}
        onChange={v => onChange(v)}
        plugins={[
            headingsPlugin({
                allowedHeadingLevels: [1, 2, 3],
            }),
            listsPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            thematicBreakPlugin(),
            imagePlugin({
                imageUploadHandler: async file => {
                    if (!file) return null
                    const { isSuccess, url } = await uploadFile(file)
                    if (!isSuccess) return null
                    return Promise.resolve(url)
                },
            }),
            directivesPlugin({
                directiveDescriptors: [YoutubeDirectiveDescriptor],
            }),
            toolbarPlugin({
                toolbarContents: () => (
                    <>
                        <UndoRedo />
                        <Separator />
                        <BoldItalicUnderlineToggles />
                        <Separator />
                        <BlockTypeSelect />
                        <Separator />
                        <ListsToggle options={['bullet', 'number']} />
                        <Separator />
                        <CreateLink />
                        <InsertImage />
                        <YouTubeButton />
                        <Separator />
                        <InsertThematicBreak />
                    </>
                ),
            }),
        ]}
    />
)

export default MarkdownEditor
