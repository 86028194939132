import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Wrapper from './components/Wrapper/Wrapper'
import Row from './components/Row/Row'

import ArrowIcon from './svg/Arrow'

import { getRowKey, getRowNumber } from './utils'

const Main = ({ href, target = '_self', children }) => {
    if (href)
        return (
            <Link to={href} target={target}>
                {children}
            </Link>
        )
    return children
}

const RowWrapper = ({
    columns,
    row,
    rowIndex,
    getRowClickHref,
    getRowClickTarget,
    isShowRowNumber,
    childrenColumnsKey,
    onColumnClick,
    pagination,
    list,
}) => {
    const [isRowHasAnyMarks, setIsRowHasAnyMarks] = useState(false)
    const [isShowChildren, setIsShowChildren] = useState(false)

    useEffect(() => {
        setIsRowHasAnyMarks(!!columns.find(item => item.getMarks(row).length !== 0))
    }, [columns, row])

    return (
        <Main key={getRowKey(row, rowIndex)} href={getRowClickHref(row)} target={getRowClickTarget(row)}>
            <div
                className={classNames('common__table__content__row-wrapper', {
                    'common__table__content__row-wrapper__is-show-children': isShowChildren,
                })}
            >
                <Wrapper
                    isRowHasAnyMarks={isRowHasAnyMarks}
                    rowNumber={isShowRowNumber ? getRowNumber(pagination, rowIndex, list) : ''}
                >
                    <Row row={row} columns={columns} onColumnClick={onColumnClick} />
                </Wrapper>
                {childrenColumnsKey && !!row[childrenColumnsKey]?.length && (
                    <div className="common__table__content__row--children">
                        <div
                            className={classNames('common__table__content__row--children__button', {
                                'common__table__content__row--children__button--is-active': isShowChildren,
                            })}
                            onClick={() => setIsShowChildren(!isShowChildren)}
                        >
                            {isShowChildren ? 'Hide translated templates' : 'Show translated templates'} (
                            {row[childrenColumnsKey].length}) <ArrowIcon />
                        </div>
                        {isShowChildren && (
                            <div className="common__table__child-content__row">
                                {row[childrenColumnsKey].map((childRow, childRowIndex) => (
                                    <Wrapper
                                        key={childRowIndex}
                                        isRowHasAnyMarks={isRowHasAnyMarks}
                                        rowNumber={isShowRowNumber ? childRowIndex + 1 : ''}
                                        isChildren
                                    >
                                        <Row
                                            row={childRow}
                                            columns={columns}
                                            parent={row}
                                            onColumnClick={onColumnClick}
                                        />
                                    </Wrapper>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Main>
    )
}

export default RowWrapper
