import React from 'react'
import moment from 'moment'

import SORT_ORDERS from 'constants/sortOrders'

import API from 'api'

import Preloader from 'components/Preloader/Preloader'
import UserBox from './components/UserBox/UserBox'
import ProjectsTable from './components/ProjectsTable/ProjectsTable'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'
import DefaultChart from 'components/Charts/DefaultChart/DefaultChart'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { asyncSetState } from 'utils/react'

import { TABLE_PARAMS } from './constants'

import './Reports.scss'

class ReportsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReadyUsers: false,
            isReadyProjects: false,

            mostActiveUsers: {
                isLoading: false,
                lastResponse: null,
                method: 'GET_MOST_ACTIVE_USERS',
                requestParams: {
                    before: moment().subtract(7, 'days').valueOf(),
                    projectCount: 1,
                    size: 5,
                },
                list: [],
            },
            sleepingUsers: {
                isLoading: false,
                lastResponse: null,
                method: 'GET_SLEEPING_USERS',
                requestParams: {
                    before: moment().subtract(7, 'days').valueOf(),
                    size: 5,
                },
                list: [],
            },
            zombieUsers: {
                isLoading: false,
                lastResponse: null,
                method: 'GET_ZOMBIE_USERS',
                requestParams: {
                    before: moment().subtract(14, 'days').valueOf(),
                    sort: `lastLoggedIn,${SORT_ORDERS.desc}`,
                    size: 5,
                },
                list: [],
            },

            projects: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },
        }
    }

    async componentDidMount() {
        const { mostActiveUsers, zombieUsers, sleepingUsers } = this.state

        Promise.all([
            this.getUsers(mostActiveUsers.method, 'mostActiveUsers', {
                ...mostActiveUsers.requestParams,
                page: 0,
            }),
            this.getUsers(sleepingUsers.method, 'sleepingUsers', {
                ...sleepingUsers.requestParams,
                page: 0,
            }),
            this.getUsers(zombieUsers.method, 'zombieUsers', {
                ...zombieUsers.requestParams,
                page: 0,
            }),
        ])
            .then(() => this.setState({ isReadyUsers: true }))
            .catch(err => {
                console.error(err)
                Toast(TOAST_TYPE.ERROR)
            })

        await this.getProjects()
    }

    getUsers = async (method, target, params) => {
        try {
            this.setState(prevState => ({
                [target]: {
                    ...prevState[target],
                    isLoading: true,
                },
            }))

            const response = await API.USERS[method](params)
            this.setState(prevState => ({
                [target]: {
                    ...prevState[target],
                    lastResponse: response,
                    list: [...prevState[target].list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                [target]: {
                    ...prevState[target],
                    isLoading: false,
                },
            }))
        }
    }

    getProjects = async (isClear = true) => {
        try {
            const {
                projects: { requestParams },
            } = this.state

            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.PROJECTS.GET_NEW_EMBEDDED_PROJECTS(requestParams)
            this.setState(prevState => ({
                isReadyProjects: true,
                projects: {
                    ...prevState.projects,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.projects.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: false,
                },
            }))
        }
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            projects: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            projects: {
                ...prevState.projects,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getProjects(isClear)
    }

    render() {
        const { isReadyUsers, isReadyProjects, mostActiveUsers, zombieUsers, sleepingUsers, projects } = this.state

        return (
            <>
                <SEO title="Reports" />

                {!isReadyUsers && !isReadyProjects ? (
                    <Preloader />
                ) : (
                    <>
                        {isReadyUsers ? (
                            <ul className="boxes">
                                <UserBox
                                    title="Most active users"
                                    note="Published more than 1 project in the last 7 days"
                                    data={mostActiveUsers}
                                    modalEndpoint={API.USERS[mostActiveUsers.method]}
                                    modalEndpointRequestParams={{
                                        ...mostActiveUsers.requestParams,
                                        size: 30,
                                    }}
                                    getValue={user => user.numberOfPublishedProject}
                                />
                                <UserBox
                                    title="Sleeping users"
                                    note="Did not publish for 7 days or more"
                                    data={sleepingUsers}
                                    modalEndpoint={API.USERS[sleepingUsers.method]}
                                    modalEndpointRequestParams={{
                                        ...sleepingUsers.requestParams,
                                        size: 30,
                                    }}
                                    modalDate={user => moment(user.lastPublished).format('MMM D, YYYY (HH:mm)')}
                                    getValue={user => moment(user.lastPublished).fromNow(true)}
                                />
                                <UserBox
                                    title="Zombie users"
                                    note="14 or more days since the last login"
                                    data={zombieUsers}
                                    modalEndpoint={API.USERS[zombieUsers.method]}
                                    modalEndpointRequestParams={{
                                        ...zombieUsers.requestParams,
                                        size: 30,
                                    }}
                                    modalDate={user => moment(user.lastLoggedIn).format('MMM D, YYYY (HH:mm)')}
                                    getValue={user => moment(user.lastLoggedIn).fromNow(true)}
                                />
                            </ul>
                        ) : (
                            <Preloader />
                        )}

                        <DefaultChart title="Users registrations" method={API.USERS.GET_USERS} />
                        <br />

                        {isReadyProjects ? (
                            <ProjectsTable
                                title="Embedded projects"
                                list={projects.list}
                                methods={{
                                    changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                                }}
                                pagination={{
                                    currentPage: projects.lastResponse.number,
                                    totalPages: projects.lastResponse.totalPages,
                                    perPageElements: projects.lastResponse.numberOfElements,
                                    totalElements: projects.lastResponse.totalElements,
                                }}
                                requestParams={projects.requestParams}
                                onExpand={() =>
                                    this.changeRequestParamsAndRefresh(
                                        [
                                            {
                                                field: 'page',
                                                value: projects.lastResponse.number + 1,
                                            },
                                        ],
                                        false,
                                    )
                                }
                                onChangePage={page =>
                                    this.changeRequestParamsAndRefresh([
                                        {
                                            field: 'page',
                                            value: page,
                                        },
                                    ])
                                }
                                isLoading={projects.isLoading}
                            />
                        ) : (
                            <Preloader />
                        )}
                    </>
                )}
            </>
        )
    }
}

export default ReportsPage
