import { NestedLexicalEditor } from '@mdxeditor/editor'

import youtubeIcon from './i/youtube.svg'

import styles from './YoutubeDirectiveDescriptor.module.scss'

export default {
    name: 'youtube',
    testNode(node) {
        return node.name === 'youtube'
    },
    attributes: [],
    hasChildren: true,
    Editor: props => (
        <div className={styles.box}>
            <div className={styles.title}>
                <img src={youtubeIcon} alt="youtube" />
                YouTube video (ID: {props.mdastNode.attributes.id})
            </div>
            <div className={styles.content}>
                <p className={styles.descriptionTitle}>Description:</p>
                <div className={styles.descriptionEditArea}>
                    <NestedLexicalEditor
                        block
                        getContent={node => node.children}
                        getUpdatedMdastNode={(mdastNode, children) => {
                            return { ...mdastNode, children }
                        }}
                    />
                </div>
            </div>
        </div>
    ),
}
