import React from 'react'

import SEO from 'components/SEO/SEO'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Button from 'components/Forms/Button/Button'
import Input from 'components/Forms/Input/Input'

import { asyncSetState } from 'utils/react'

import API from 'api'

import TagsList from './components/TagsList/TagsList'
import RemoveTagModal from './components/RemoveTagModal/RemoveTagModal'
import CreateTagModal from './components/CreateTagModal/CreateTagModal'
import EditTagModal from './components/EditTagModal/EditTagModal'

import { MODALS } from './constants'

class Tags extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            filter: '',
            tags: {
                isLoading: false,
                list: [],
            },
            modal: {
                isOpen: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getTags()
        this.setState({
            isReady: true,
        })
    }

    getTags = async () => {
        try {
            await asyncSetState(this, prevState => ({
                tags: {
                    ...prevState.tags,
                    isLoading: true,
                },
            }))

            const { content } = await API.AUTHORS.GET_AUTHORS_TAGS()

            await asyncSetState(this, prevState => ({
                tags: {
                    ...prevState.tags,
                    list: content,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            await asyncSetState(this, prevState => ({
                tags: {
                    ...prevState.tags,
                    isLoading: false,
                },
            }))
        }
    }
    createTag = async name => {
        await API.AUTHORS.CREATE_AUTHORS_TAG(name)
        await this.getTags()
    }
    editTag = async (id, name) => {
        await API.AUTHORS.UPDATE_AUTHORS_TAG(id, name)
        await this.getTags()
    }
    removeTag = async id => {
        await API.AUTHORS.REMOVE_AUTHORS_TAG(id)
        await this.getTags()
    }

    openModal = async (name, payload = null) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }

    render() {
        const { isReady, tags, modal, filter } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        const filtered = list => list.filter(tag => tag.name.toLowerCase().match(new RegExp(filter.toLowerCase(), 'g')))

        return (
            <>
                <SEO title="Authors / Tags" />

                {isReady ? (
                    <>
                        <div className="top-bar">
                            <div className="top-bar--left">
                                <Input
                                    maxWidth="300px"
                                    isClearable
                                    placeholder="Name filter"
                                    value={filter}
                                    onChange={val => this.setState({ filter: val })}
                                />
                            </div>
                            <div className="top-bar--right">
                                <Button
                                    content="+ Create tag"
                                    variant="primary"
                                    onClick={() => this.openModal(MODALS.CREATE_TAG)}
                                />
                            </div>
                        </div>
                        <br />
                        <TagsList list={filtered(tags.list)} openModal={this.openModal} />
                        {isOpenedModal(MODALS.REMOVE_TAG) && (
                            <RemoveTagModal data={modal.payload} onSubmit={this.removeTag} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODALS.CREATE_TAG) && (
                            <CreateTagModal
                                data={{
                                    existTagsNames: tags.list.map(tag => tag.name),
                                }}
                                onSubmit={this.createTag}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.EDIT_TAG) && (
                            <EditTagModal
                                data={{
                                    tag: modal.payload,
                                    existTagsNames: tags.list.map(tag => tag.name),
                                }}
                                onSubmit={this.editTag}
                                onClose={this.closeModal}
                            />
                        )}
                    </>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default Tags
