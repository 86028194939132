import React from 'react'

import SEO from 'components/SEO/SEO'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Preloader from 'components/Preloader/Preloader'

import API from 'api'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { asyncSetState } from 'utils/react'

import OrganizationsTable from './components/OrganizationsTable/OrganizationsTable'

import { TABLE_PARAMS } from './constants'

class OrganizationsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            organizations: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getOrganizations()
        this.setState({
            isReady: true,
        })
    }

    getOrganizations = async (isClear = true) => {
        try {
            const {
                organizations: { requestParams },
            } = this.state

            this.setState(prevState => ({
                organizations: {
                    ...prevState.organizations,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.ORGANIZATIONS.GET_ORGANIZATIONS(requestParams)
            this.setState(prevState => ({
                organizations: {
                    ...prevState.organizations,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.organizations.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                organizations: {
                    ...prevState.organizations,
                    isLoading: false,
                },
            }))
        }
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            organizations: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            organizations: {
                ...prevState.organizations,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getOrganizations(isClear)
    }

    render() {
        const { isReady, organizations } = this.state

        return isReady ? (
            <>
                <SEO title="Organizations" />

                <OrganizationsTable
                    title="Organizations"
                    list={organizations.list}
                    methods={{
                        changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                    }}
                    pagination={{
                        currentPage: organizations.lastResponse.number,
                        totalPages: organizations.lastResponse.totalPages,
                        perPageElements: organizations.lastResponse.numberOfElements,
                        totalElements: organizations.lastResponse.totalElements,
                    }}
                    requestParams={organizations.requestParams}
                    onExpand={() =>
                        this.changeRequestParamsAndRefresh(
                            [
                                {
                                    field: 'page',
                                    value: organizations.lastResponse.number + 1,
                                },
                            ],
                            false,
                        )
                    }
                    onChangePage={page =>
                        this.changeRequestParamsAndRefresh([
                            {
                                field: 'page',
                                value: page,
                            },
                        ])
                    }
                    isLoading={organizations.isLoading}
                />
            </>
        ) : (
            <Preloader />
        )
    }
}

export default OrganizationsPage
