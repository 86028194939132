import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Avatar from 'components/Avatar/Avatar'
import Tabs from 'components/Tabs/Tabs'

import AccountAndProjectsTab from './Tabs/AccountAndProjects/AccountAndProjects'
import SubscriptionTab from './Tabs/Subscription/Subscription'
import PublicProfileTab from './Tabs/PublicProfile/PublicProfile'
import BillingHistoryTab from './Tabs/BillingHistory/BillingHistory'
import OrganizationsTab from './Tabs/Organizations/Organizations'

import { getUserName } from 'utils/user'
import { asyncSetState } from 'utils/react'

import ROUTES from 'constants/routes'
import { PRODUCT_GROUPS } from 'constants/products'

import API from 'api'

import { isPublicProfile } from './Tabs/PublicProfile/utils'

import './User.scss'

class UserPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            userId: null,
            user: null,
            userPublicProfile: null,
            userVisibleName: null,
        }
    }

    async componentDidMount() {
        const { id } = this.props.match.params

        await asyncSetState(this, { userId: id })

        await this.updateUserData()

        this.setState({
            isReady: true,
        })
    }

    updateUserData = async () => await Promise.all([this.getUser(), this.getUserPublicProfile()])

    getUser = async () => {
        try {
            const { userId } = this.state
            const user = await API.USERS.GET_USER(userId)
            this.setState({
                user,
                userVisibleName: getUserName(user),
            })
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }

    getUserPublicProfile = async () => {
        try {
            const { userId } = this.state
            const userPublicProfile = await API.USERS.GET_USER_PUBLIC_PROFILE(userId)
            this.setState({
                userPublicProfile,
            })
        } catch (err) {}
    }

    getBreadcrumbsMarks = user => {
        let result = []

        if (user.isTest) result.push({ title: 'This is TEST account', label: 'TEST' })

        if (user.selectedSubscription && user.selectedSubscription.product.group) {
            const group = user.selectedSubscription.product.group

            if (group === PRODUCT_GROUPS.BUSINESS) result.push({ title: 'User from Business group', label: 'Business' })
            if (group === PRODUCT_GROUPS.EDUCATION)
                result.push({ title: 'User from Education group', label: 'Education' })
            if (group === PRODUCT_GROUPS.APPSUMO)
                result.push({ title: 'User from AppSumo group', label: 'AppSumo', backgroundColor: '#FFBC00' })
        }

        return result
    }

    render() {
        const { isReady, userId, user, userPublicProfile, userVisibleName } = this.state

        return (
            <>
                {isReady ? (
                    <>
                        <div className="top-bar">
                            <div className="top-bar--left">
                                <Breadcrumbs
                                    list={[
                                        {
                                            to: ROUTES.USERS.path,
                                            label: 'Users',
                                        },
                                        {
                                            to: null,
                                            label: <>{userVisibleName}</>,
                                            marks: this.getBreadcrumbsMarks(user),
                                        },
                                    ]}
                                />
                                <br />
                                <br />
                                <Tabs
                                    isVisibleBottomLine={false}
                                    list={[
                                        {
                                            to: `${ROUTES.USERS.path}/${userId}`,
                                            label: 'Account and projects',
                                        },
                                        {
                                            to: `${ROUTES.USERS.path}/${userId}/subscription`,
                                            label: 'Subscription',
                                        },
                                        userPublicProfile && {
                                            to: `${ROUTES.USERS.path}/${userId}/public-profile`,
                                            label: 'Public profile',
                                            tag: {
                                                text: isPublicProfile(userPublicProfile) ? 'PUBLIC' : 'PRIVATE',
                                                backgroundColor: isPublicProfile(userPublicProfile)
                                                    ? '#65bb5a'
                                                    : '#ff5656',
                                                title: isPublicProfile(userPublicProfile)
                                                    ? 'This is public profile'
                                                    : 'This is private profile',
                                            },
                                        },
                                        {
                                            to: `${ROUTES.USERS.path}/${userId}/organizations`,
                                            label: 'Organizations',
                                        },
                                        {
                                            to: `${ROUTES.USERS.path}/${userId}/billing-history`,
                                            label: 'Billing History',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="top-bar--right">
                                <Avatar
                                    user={user}
                                    style={{
                                        fontSize: 22,
                                        width: 96,
                                        height: 96,
                                        boxShadow: 'rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="divider"></div>

                        <br />
                        <br />

                        <Switch>
                            <Route
                                exact
                                path={`${ROUTES.USERS.path}/:id`}
                                render={() => (
                                    <AccountAndProjectsTab
                                        user={user}
                                        userVisibleName={userVisibleName}
                                        location={this.props.location}
                                        history={this.props.history}
                                        methods={{
                                            getUser: this.updateUserData,
                                        }}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${ROUTES.USERS.path}/:id/subscription`}
                                render={() => (
                                    <SubscriptionTab
                                        user={user}
                                        userVisibleName={userVisibleName}
                                        methods={{
                                            getUser: this.updateUserData,
                                        }}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${ROUTES.USERS.path}/:id/public-profile`}
                                render={() => (
                                    <PublicProfileTab
                                        userPublicProfile={userPublicProfile}
                                        userVisibleName={userVisibleName}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${ROUTES.USERS.path}/:id/organizations`}
                                render={() => <OrganizationsTab user={user} userVisibleName={userVisibleName} />}
                            />
                            <Route
                                exact
                                path={`${ROUTES.USERS.path}/:id/billing-history`}
                                render={() => (
                                    <BillingHistoryTab
                                        user={user}
                                        userVisibleName={userVisibleName}
                                        methods={{
                                            getUser: this.updateUserData,
                                        }}
                                    />
                                )}
                            />
                            <Redirect
                                from="*"
                                to={{
                                    pathname: ROUTES.ERROR.path,
                                    state: {
                                        errorCode: 404,
                                    },
                                }}
                            />
                        </Switch>
                    </>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default UserPage
