export const getRowKey = (row, i) => {
    if (row.id) return row.id
    return i
}

export const getRowNumber = (pagination, rowIndex, list) => {
    if (!pagination) return rowIndex + 1

    const { totalElements, currentPage, perPageElements, totalPages } = pagination
    const currentElements = list.length

    return (
        (totalElements
            ? (currentPage + 1 === totalPages ? totalElements : perPageElements * (currentPage + 1)) -
              currentElements +
              1
            : 0) + rowIndex
    )
}
