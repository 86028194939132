import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import Pagination from './components/Pagination/Pagination'
import RowWrapper from './components/RowWrapper/RowWrapper'

import { getNormalizedColumns, getSortIconByOrder, getReversedOrder } from './utils'

import preloaderImage from './i/preloader.gif'

import './Table.scss'

const ShowMoreBtn = ({ isLoading, onExpand }) => (
    <div className="common__table__show-more">
        <span className={isLoading ? 'is-disabled' : ''} onClick={() => onExpand()}>
            Show more
        </span>
    </div>
)

const Counter = ({ pagination, currentElements }) => {
    if (!pagination) {
        return (
            <div className="common__table__info__counter">
                {currentElements ? 1 : 0} - {currentElements} / <b>{currentElements}</b>
            </div>
        )
    }
    const { totalElements, currentPage, perPageElements, totalPages } = pagination
    const to = currentPage + 1 === totalPages ? totalElements : perPageElements * (currentPage + 1)
    const from = totalElements ? to - currentElements + 1 : 0

    return (
        <div className="common__table__info__counter">
            {from} - {to} / <b>{totalElements}</b>
        </div>
    )
}

const Loader = () => (
    <div className="common__table__loader">
        <img src={preloaderImage} alt="" />
    </div>
)

const executeScroll = ref => {
    const scrollableElement = document.getElementById('dashboard-content')
    if (!scrollableElement) return
    scrollableElement.scrollTo({
        top: ref.current.offsetTop - 60 - 10,
        left: 0,
        behavior: 'smooth',
    })
}

const Table = ({
    title,
    columns = [],
    list = [],
    isLoading = false,
    pagination = null,
    getRowClickHref = () => null,
    getRowClickTarget = () => null,
    onColumnClick = null,
    onExpand = () => {},
    onChangePage = () => {},
    onChangeSort = () => {},
    minWidth = 1240,
    isShowCounter = true,
    isShowRowNumber = true,
    isExpandable = true,
    isPageable = true,
    childrenColumnsKey = null,
}) => {
    const tableRef = useRef(null)
    const [initialColumns] = useState(columns)
    const [normalizedColumns, setNormalizedColumns] = useState(() => getNormalizedColumns(initialColumns))

    useEffect(() => {
        if (!isEqual(columns, initialColumns)) setNormalizedColumns(getNormalizedColumns(columns))
    }, [columns, initialColumns])

    return (
        <div ref={tableRef} className="common__table">
            <div className="common__table__info">
                <div className="common__table__info__title">{title || null}</div>
                {isShowCounter && <Counter currentElements={list.length} pagination={pagination} />}
            </div>
            <div className="common__table--scrollable-wrapper">
                <div style={{ minWidth }}>
                    <div className="common__table__header">
                        <ul className="common__table__header__row">
                            {normalizedColumns.map((column, columnIndex) => (
                                <li
                                    className="common__table__header__col"
                                    key={columnIndex}
                                    style={{ width: column.width, ...column.headerStyles }}
                                >
                                    <div
                                        className={classNames({
                                            common__table__header__col__label: true,
                                            'with-sort-param': column.sort,
                                        })}
                                        onClick={() => {
                                            if (!column.sort) return
                                            onChangeSort({
                                                key: column.sort.key,
                                                order: getReversedOrder(column.sort.order),
                                            })
                                        }}
                                    >
                                        <p>{column.headerLabel}</p>
                                        {column.sort && <img src={getSortIconByOrder(column.sort.order)} alt="" />}
                                    </div>
                                    {column.headerFilter && (
                                        <div className="common__table__header__col__filter">
                                            {typeof column.headerFilter === 'function'
                                                ? column.headerFilter()
                                                : column.headerFilter}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {isLoading && !list.length ? (
                        <div className="common__table__content__loading">Loading...</div>
                    ) : (
                        <>
                            <div
                                className={classNames('common__table__content', {
                                    'is-loading': isLoading,
                                })}
                            >
                                {list.length ? (
                                    list.map((row, rowIndex) => (
                                        <RowWrapper
                                            key={row.id || rowIndex}
                                            columns={normalizedColumns}
                                            row={row}
                                            list={list}
                                            rowIndex={rowIndex}
                                            getRowClickHref={getRowClickHref}
                                            getRowClickTarget={getRowClickTarget}
                                            isShowRowNumber={isShowRowNumber}
                                            childrenColumnsKey={childrenColumnsKey}
                                            onColumnClick={onColumnClick}
                                            pagination={pagination}
                                        />
                                    ))
                                ) : (
                                    <div className="common__table__content__empty">Nothing was found</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!!list.length && pagination && (
                <>
                    {isExpandable && pagination.currentPage + 1 < pagination.totalPages && (
                        <ShowMoreBtn isLoading={isLoading} onExpand={onExpand} />
                    )}
                    {isPageable && pagination.totalPages > 1 && (
                        <Pagination
                            currentPage={pagination.currentPage}
                            totalPages={pagination.totalPages}
                            isLoading={isLoading}
                            onChange={async page => {
                                await onChangePage(page)
                                executeScroll(tableRef)
                            }}
                        />
                    )}
                </>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default Table
