import React from 'react'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import API from 'api'

import { capitalize } from 'utils/common'
import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { getFormattedNumber } from 'utils/numbers'
import { asyncSetState } from 'utils/react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Preloader from 'components/Preloader/Preloader'
import SEO from 'components/SEO/SEO'
import Button from 'components/Forms/Button/Button'

import UsersTable from './components/UsersTable/UsersTable'
import UsersCounter from './components/UsersCounter/UsersCounter'
import CreateNewUser from './components/CreateNewUser/CreateNewUser'

import { TABLE_PARAMS, PAID_ONLY_PLAN_VALUE } from './constants'

class UsersPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReadyUsersCount: false,
            usersCount: {
                today: {
                    label: 'Today',
                    value: null,
                },
                last7Days: {
                    label: 'Last 7 days',
                    value: null,
                },
                last30Days: {
                    label: 'Last 30 days',
                    value: null,
                },
                // must be last
                total: {
                    label: 'Total',
                    value: null,
                },
            },

            subscriptionPlansOptions: [],
            providersOptions: [],
            groupsOptions: [],
            rolesInGroupsOptions: {},

            isReadyUsers: false,
            users: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },

            isOpenedCreateNewUserModal: false,
        }
    }

    async componentDidMount() {
        ;(async () => {
            const [subscriptionPlans, providers, groups] = await Promise.all([
                API.COMMON.GET_PRODUCTS(),
                API.USERS.GET_PROVIDERS(),
                API.USERS.GET_ROLES(),
                this.getUsers(),
            ])

            await asyncSetState(this, {
                subscriptionPlansOptions: [
                    {
                        value: null,
                        label: 'All',
                    },
                    {
                        value: PAID_ONLY_PLAN_VALUE,
                        label: 'All paid 💵',
                    },
                    ...subscriptionPlans.map(el => ({
                        value: el.id,
                        label: el.name,
                    })),
                ],
                providersOptions: [
                    {
                        value: null,
                        label: 'All',
                    },
                    ...providers.map(el => ({
                        value: el,
                        label: el === 'local' ? 'Login / Password' : capitalize(el),
                    })),
                ],
                groupsOptions: [
                    {
                        value: null,
                        label: 'All',
                    },
                    ...groups.map(el => ({
                        value: el.id,
                        label: capitalize(el.groupTranslationDto.text),
                    })),
                ],
            })

            // Set roles, <GROUP_ID>: [<OPTIONS>]
            let rolesInGroupsOptions = {}
            for (const group of groups) {
                rolesInGroupsOptions[group.id] = [
                    {
                        value: null,
                        label: 'All',
                    },
                    ...group.userRoleInProjectDtos.map(el => ({
                        value: el.id,
                        label: capitalize(el.roleTranslationDto.text),
                    })),
                ]
            }
            await asyncSetState(this, { rolesInGroupsOptions })

            this.setState({
                isReadyUsers: true,
            })
        })()
        ;(async () => {
            await Promise.all([
                this.getUsersCountFromDateRange('today'),
                this.getUsersCountFromDateRange('last7Days', 7),
                this.getUsersCountFromDateRange('last30Days', 30),
                this.getUsersCountFromDateRange('total'),
            ])
            this.setState({
                isReadyUsersCount: true,
            })
        })()
    }

    getUsers = async (isClear = true) => {
        try {
            const {
                users: { requestParams },
            } = this.state

            this.setState(prevState => ({
                users: {
                    ...prevState.users,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.USERS.GET_USERS(requestParams)
            this.setState(prevState => ({
                users: {
                    ...prevState.users,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.users.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                users: {
                    ...prevState.users,
                    isLoading: false,
                },
            }))
        }
    }

    getUsersCountFromDateRange = async (
        key,
        fromAmountSubstract = 0,
        fromUnitSubtract = 'day',
        fromStartOf = 'day',
        toAmountSubstract = 0,
        toUnitSubtract = 'day',
        toEndOf = 'day',
    ) => {
        try {
            let value = 0

            if (key === 'total') {
                value = await API.USERS.GET_USERS_COUNT()
            } else {
                const response = await API.USERS.GET_USERS({
                    size: 1,
                    page: 0,
                    from: moment().subtract(fromAmountSubstract, fromUnitSubtract).startOf(fromStartOf).valueOf(),
                    to: moment().subtract(toAmountSubstract, toUnitSubtract).endOf(toEndOf).valueOf(),
                })
                value = response.totalElements
            }

            await asyncSetState(this, prevState => ({
                usersCount: {
                    ...prevState.usersCount,
                    [key]: {
                        ...prevState.usersCount[key],
                        value: getFormattedNumber(value, { thousand: ',' }),
                    },
                },
            }))
        } catch (err) {
            console.error(err)
        }
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            users: { requestParams },
        } = this.state

        const additionalData = []
        let _data = cloneDeep(data).map(el => {
            const result = { ...el }
            if (el.field === 'subscriptionPlanId') {
                if (el.value === PAID_ONLY_PLAN_VALUE) {
                    result.field = 'isPaidOnly'
                    result.value = true
                    additionalData.push({
                        field: 'subscriptionPlanId',
                        value: null,
                    })
                } else {
                    additionalData.push({
                        field: 'isPaidOnly',
                        value: null,
                    })
                }
            }
            return result
        })

        _data = [..._data, ...additionalData]

        if (_data.find(item => item.field !== 'page')) {
            _data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            users: {
                ...prevState.users,
                requestParams: getRefreshedParams(requestParams, _data),
            },
        }))

        await this.getUsers(isClear)
    }

    singUpNewUser = async data => {
        await API.USERS.CREATE_USER(data)
        await this.getUsers()
    }

    render() {
        const {
            subscriptionPlansOptions,
            providersOptions,
            groupsOptions,
            rolesInGroupsOptions,
            isReadyUsers,
            users,
            isReadyUsersCount,
            usersCount,
            isOpenedCreateNewUserModal,
        } = this.state

        return (
            <>
                <SEO title="Users" />

                {isReadyUsersCount && <UsersCounter items={Object.values(usersCount)} />}
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
                    <Button
                        content="+ Create new user"
                        variant="primary"
                        onClick={() => this.setState({ isOpenedCreateNewUserModal: true })}
                    />
                </div>
                {isReadyUsers ? (
                    <UsersTable
                        title="Users"
                        payload={{
                            subscriptionPlansOptions,
                            providersOptions,
                            groupsOptions,
                            rolesInGroupsOptions,
                        }}
                        list={users.list}
                        methods={{
                            openModal: this.openModal,
                            modalAction: this.modalAction,
                            changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                        }}
                        pagination={{
                            currentPage: users.lastResponse.number,
                            totalPages: users.lastResponse.totalPages,
                            perPageElements: users.lastResponse.numberOfElements,
                            totalElements: users.lastResponse.totalElements,
                        }}
                        requestParams={users.requestParams}
                        onExpand={() =>
                            this.changeRequestParamsAndRefresh(
                                [
                                    {
                                        field: 'page',
                                        value: users.lastResponse.number + 1,
                                    },
                                ],
                                false,
                            )
                        }
                        onChangePage={page =>
                            this.changeRequestParamsAndRefresh([
                                {
                                    field: 'page',
                                    value: page,
                                },
                            ])
                        }
                        isLoading={users.isLoading}
                    />
                ) : (
                    <Preloader />
                )}

                {isOpenedCreateNewUserModal && (
                    <CreateNewUser
                        onSubmit={this.singUpNewUser}
                        onClose={() => this.setState({ isOpenedCreateNewUserModal: false })}
                    />
                )}
            </>
        )
    }
}

export default UsersPage
